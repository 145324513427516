html, body, #root {
  height: 100%;
}

html {
  margin-left: calc(100vw - 100%);
}

a {
  color: #78909c;
  text-decoration: underline !important;
}

h5 {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0;
}

table td {
  vertical-align: text-top;
  padding: 0 16px 0 0;
}

table td:first-child {
  white-space: nowrap;
  min-width:108px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.54);
}

.grid-item, .grid-sizer, .stamp {
  width: 280px;
}

.grid-item.first {
  width: 560px;
}

.masonry {
  width: 1402px;
  margin: 0 auto;
}

@media screen and (max-width: 1440px){
  .masonry {
    width: 1122px;
  }
}

@media screen and (max-width: 1280px){
  .masonry {
    width:842px;
  }
}

@media screen and (max-width: 1024px){
  .masonry {
    width: 562px;
  }
  .grid-item.first {
    width:280px;
  }
}

@media screen and (max-width: 600px){
  .masonry, .grid-item, .grid-sizer, .stamp, .grid-item.first {
    width:100%;
  }
}
